<template>
  <div :class="$style.e_gift_wrapper">
    <div :class="$style.e_gift_container">
      <EGiftHeader />
      <NuxtPage />
    </div>
    <div id="e-gift-bg-line-top-left" :class="$style.bg_line" />
    <div id="e-gift-bg-line-bottom-right" :class="[$style.bg_line, $style.bottom_right]" />
    <OrganismsToast />
  </div>
</template>

<style lang="scss" scoped module>
.e_gift_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  z-index: 1;

  .e_gift_container {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 100%;
    font-size: $font-size-14;
    padding: 0 1rem 2rem 1rem;
    margin-top: 80px;
  }

  .bg_line {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: url("/images/site/e-gift/bg-line.svg") no-repeat;
    z-index: -10;
    pointer-events: none;
    opacity: 0;
  }

  .bg_line.bottom_right {
    transform: rotate(180deg);
  }
}
</style>
