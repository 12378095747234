<template>
  <header id="e-gift-header" :class="$style.e_gift_header">
    <div :class="$style.header">
      <div :class="[$style.container, $style.header_container]">
        <div :class="$style.logo">
          <AtomsConsumerTentialLogo variant="text" :width="240" :height="24" />
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped module>
.e_gift_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  opacity: 0;
  z-index: 30;
  &.hide {
    transform: translateY(-100%);
  }

  @include md {
    z-index: 98;
    &.hide {
      transform: none;
    }
  }

  .container {
    max-width: 1135px;
    margin: 0 auto;
  }
  .header {
    position: relative;
    z-index: 5;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 1.5rem;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
